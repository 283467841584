<template>
  <div class="public-tabs">
    <div class="tabs-list">
      <swiper ref="tabSwiper" :options="swiperOption">
        <swiper-slide v-for="(item, index) in dataInfo" :key="index" class="tabs">
          <div
            class="tabs-li"
            :class="`${defaultIndex === index ? 'tab-active' : ''}`"
            @click="handleTab(item, index)"
          >
            {{ item.title }}
          </div>
        </swiper-slide>
      </swiper>
      <div v-show="showMoveBtns">
        <div slot="button-prev" class="swiper-button-prev join-button-prev">
          <span class="iconfont icon-a-zuojiantou1x"></span>
        </div>
        <div slot="button-next" class="swiper-button-next join-button-next">
          <span class="iconfont icon-a-youjiantou1x"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  name: 'PublicTabs',
  components: {
    swiper,
    swiperSlide
  },
  props: {
    // 菜单分类
    dataInfo: {
      type: [Array],
      default: () => []
    },
    // 默认显示第一个tab
    defaultIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      showMoveBtns: false,
      timer: null,
      swiperOption: {
        slidesPerView: 'auto', // 每屏显示个数
        allowTouchMove: false, // 禁止拖动
        centerInsufficientSlides: true, // 居中
        navigation: {
          nextEl: '.join-button-next',
          prevEl: '.join-button-prev'
        },
        on: {
          init: () => {
            this.$nextTick(() => {
              this.handleShowMoveBtns()
            })
          }
        }
      }
    }
  },
  watch: {
    dataInfo: {
      handler: function () {
        this.handleShowMoveBtns()
      },
      deep: true
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleShowMoveBtns)
  },
  beforeDestroy() {
    if (this.$refs.tabSwiper) {
      this.$refs.tabSwiper.swiper.destroy(false)
    }
    window.removeEventListener('resize', this.handleShowMoveBtns)
  },
  methods: {
    // 切换tab菜单
    handleTab(item, index) {
      this.$emit('handleTab', item, index)
    },
    // 判断是否显示左右切换按钮，需满足(存在数据，swiper渲染完成，isBeginning和isEnd不同时为true)
    handleShowMoveBtns() {
      if (this.timer) clearTimeout(this.timer)
      setTimeout(() => {
        const refswiper = this.$refs.tabSwiper
        this.showMoveBtns =
          !!this.dataInfo.length && !!refswiper && (!refswiper.swiper.isBeginning || !refswiper.swiper.isEnd)
        // 窗口大小变换时，将点击项默认滑到第一位
        if (!!refswiper && refswiper.swiper.activeIndex !== this.defaultIndex) {
          refswiper.swiper.slideTo(this.defaultIndex)
        }
      }, 300)
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.public-tabs {
  margin-bottom: 40px;
  @media (max-width: 1200px) {
    margin-bottom: 2.85%;
  }
  .tabs-list {
    border-top: 1px solid #DDDDDD;
    border-bottom: 2px solid #DDDDDD;
    position: relative;
    .tabs {
      width: auto;
      .tabs-li {
        font-size: 18px;
        color: #979797;
        line-height: 24px;
        font-weight: 500;
        margin: 0 60px;
        background: #ffffff;
        cursor: pointer;
        padding: 20px 0;
        border-bottom: 2px solid transparent;
        .transition();
        &:hover {
          color: #232931;
        }
      }
      .tab-active {
        color: #232931;
        border-bottom: 2px solid #232931;
      }
    }
  }
  // swiper样式重置
  /deep/ .swiper-container {
    width: 90%;
  }
  /deep/ .swiper-button-prev,
  .swiper-button-next {
    background-image: none;
    height: 42px;
    line-height: 42px;
    text-align: center;
  }
  @media screen and (max-width: 1200px) {
    .title-box {
      & > h4 {
        font-size: 28px;
        line-height: 41px;
      }
    }
    .tabs-list {
      .tabs {
        .tabs-li {
          font-size: 14px;
          line-height: 20px;
          margin: 0 40px;
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .title-box {
      padding: 40px 0 24px;
      & > h4 {
        font-size: 24px;
        line-height: 36px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .tabs-list {
      .tabs {
        .tabs-li {
          font-size: 13px;
          line-height: 1;
          margin: 0 10px;
        }
      }
    }
    /deep/ .swiper-container {
      width: 80%;
    }
    /deep/ .swiper-button-prev {
      left: 0px;
    }
    /deep/ .swiper-button-next{
      right: 0;
    }
  }
}
</style>
